export const errorIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.73 0H5.27L0 5.27V12.73L5.27 18H12.73L18 12.73V5.27L12.73 0ZM9 14.3C8.28 14.3 7.7 13.72 7.7 13C7.7 12.28 8.28 11.7 9 11.7C9.72 11.7 10.3 12.28 10.3 13C10.3 13.72 9.72 14.3 9 14.3ZM10 10H8V4H10V10Z"
      fill="#410E0B"
    />
  </svg>
);

export const warningIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
  >
    <path
      d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z"
      fill="#7C5705"
    />
  </svg>
);
export const infoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
      fill="#49454F"
    />
  </svg>
);
export const successIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
      fill="#307111"
    />
  </svg>
);

export const charRedDot = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <circle
      id="Ellipse 13"
      cx="8.5"
      cy="8.5"
      r="7.5"
      fill="url(#paint0_linear_9289_9314)"
      stroke="white"
      stroke-width="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9289_9314"
        x1="8.5"
        y1="0"
        x2="8.5"
        y2="17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBEED4" />
        <stop offset="1" stop-color="#FCD2EE" />
      </linearGradient>
    </defs>
  </svg>
);

export const charGreenDot = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <circle
      id="Ellipse 13"
      cx="8.5"
      cy="8.5"
      r="7.5"
      fill="#D8F2DE"
      stroke="white"
      stroke-width="2"
    />
  </svg>
);
export const uploadCloud = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <g clip-path="url(#clip0_8859_457312)">
      <path
        d="M26.3 14.2291C25.3933 9.62911 21.3533 6.17578 16.5 6.17578C12.6467 6.17578 9.3 8.36245 7.63333 11.5624C3.62 11.9891 0.5 15.3891 0.5 19.5091C0.5 23.9224 4.08667 27.5091 8.5 27.5091H25.8333C29.5133 27.5091 32.5 24.5224 32.5 20.8424C32.5 17.3224 29.7667 14.4691 26.3 14.2291ZM25.8333 24.8424H8.5C5.55333 24.8424 3.16667 22.4558 3.16667 19.5091C3.16667 16.7758 5.20667 14.4958 7.91333 14.2158L9.34 14.0691L10.0067 12.8024C11.2733 10.3624 13.7533 8.84245 16.5 8.84245C19.9933 8.84245 23.0067 11.3224 23.6867 14.7491L24.0867 16.7491L26.1267 16.8958C28.2067 17.0291 29.8333 18.7758 29.8333 20.8424C29.8333 23.0424 28.0333 24.8424 25.8333 24.8424ZM11.1667 18.1758H14.5667V22.1758H18.4333V18.1758H21.8333L16.5 12.8424L11.1667 18.1758Z"
        fill="#322F35"
      />
    </g>
    <defs>
      <clipPath id="clip0_8859_457312">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0.5 0.842285)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const browseFile = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      d="M14 12.0923V14.3423H5V12.0923H3.5V14.3423C3.5 15.1673 4.175 15.8423 5 15.8423H14C14.825 15.8423 15.5 15.1673 15.5 14.3423V12.0923H14ZM5.75 7.59229L6.8075 8.64979L8.75 6.71479V12.8423H10.25V6.71479L12.1925 8.64979L13.25 7.59229L9.5 3.84229L5.75 7.59229Z"
      fill="white"
    />
  </svg>
);
export const pdfIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="115"
    height="107"
    viewBox="0 0 115 125"
    fill="none"
  >
    <g clip-path="url(#clip0_8859_457329)">
      <path
        d="M22.8561 0.684013C16.0737 0.684013 10.5527 6.16722 10.5527 12.8981V106.698C10.5527 113.429 16.0737 118.912 22.8561 118.912H95.1284C101.911 118.912 107.432 113.429 107.432 106.698V29.5684C107.432 29.5684 107.625 27.3099 106.637 25.1121C105.711 23.052 104.202 21.6182 104.202 21.6182C104.198 21.6131 104.195 21.608 104.191 21.603L86.2597 4.14799C86.2499 4.13803 86.24 4.12817 86.2299 4.11842C86.2299 4.11842 84.699 2.67169 82.4296 1.70522C79.7575 0.567255 77.0033 0.687382 77.0033 0.687382L77.0444 0.683594L22.8561 0.684013Z"
        fill="#05735A"
      />
      <path
        d="M22.8564 4.69629H77.0447C77.0584 4.69643 77.0721 4.69643 77.0858 4.69629C77.0858 4.69629 79.2526 4.71713 80.8376 5.39212C82.363 6.04177 83.4447 7.02519 83.4519 7.03178C83.4519 7.0318 83.4593 7.03936 83.4593 7.03936L101.346 24.4536C101.346 24.4536 102.423 25.5827 102.946 26.7447C103.367 27.6826 103.393 29.3985 103.393 29.3985C103.391 29.4268 103.39 29.4552 103.389 29.4836V106.699C103.389 111.277 99.7417 114.901 95.1286 114.901H22.8564C18.2433 114.901 14.5957 111.277 14.5957 106.699V12.8984C14.5957 8.31991 18.2433 4.69629 22.8564 4.69629Z"
        fill="#F5F5F5"
      />
      <path
        d="M34.7434 71.0765C31.9619 68.316 34.9713 64.5227 43.1383 60.4947L48.2771 57.9602L50.2791 53.613C51.3802 51.2221 53.0235 47.3208 53.931 44.9436L55.581 40.6214L54.4439 37.4227C53.0459 33.4896 52.5474 27.5792 53.4344 25.454C54.6359 22.5752 58.5714 22.8706 60.1294 25.9564C61.3463 28.3665 61.2219 32.7309 59.7795 38.2352L58.5969 42.7477L59.6385 44.5026C60.2114 45.4678 61.8848 47.7595 63.3572 49.5954L66.1245 53.0116L69.5679 52.5656C80.5072 51.1489 84.2541 53.5567 84.2541 57.009C84.2541 61.3661 75.6644 61.7257 68.4512 56.6978C66.8282 55.5665 65.7137 54.4427 65.7137 54.4427C65.7137 54.4427 61.1949 55.3557 58.9701 55.9508C56.6735 56.5651 55.5278 56.9498 52.1638 58.0767C52.1638 58.0767 50.9835 59.7779 50.2143 61.0145C47.3528 65.6155 44.0118 69.4284 41.6257 70.8162C38.9542 72.37 36.1535 72.4759 34.7434 71.0765ZM39.1085 69.5288C40.672 68.5698 43.8366 64.8552 46.0273 61.4076L46.9142 60.0118L42.8757 62.0269C36.6384 65.1393 33.7849 68.0727 35.2687 69.847C36.1023 70.8438 37.0994 70.7611 39.1085 69.5288ZM79.6236 58.2424C81.1529 57.1794 80.9309 55.0371 79.2019 54.1734C77.8564 53.5012 76.772 53.3632 73.2766 53.4144C71.1286 53.5597 67.6747 53.9893 67.0893 54.12C67.0893 54.12 68.9868 55.4215 69.8292 55.8998C70.9506 56.5355 73.6758 57.7155 75.6655 58.3199C77.6281 58.9161 78.7634 58.8533 79.6236 58.2424ZM63.333 51.5228C62.4075 50.5572 60.8337 48.542 59.8358 47.0445C58.5306 45.3457 57.8761 44.1472 57.8761 44.1472C57.8761 44.1472 56.9222 47.1924 56.1395 49.0249L53.6978 55.0146L52.9898 56.3733C52.9898 56.3733 56.7535 55.1486 58.6684 54.6522C60.6965 54.1264 64.8128 53.3231 64.8128 53.3231L63.333 51.5228ZM58.0832 30.6287C58.3192 28.6626 58.4194 26.6994 57.7829 25.7094C56.0174 23.794 53.8865 25.3913 54.2475 29.9451C54.3689 31.4771 54.7524 34.0959 55.2651 35.71L56.1972 38.6447L56.8533 36.4344C57.2141 35.2187 57.7676 32.6061 58.0832 30.6287Z"
        fill="#05735A"
      />
      <path
        d="M39.4727 83.8594H43.9885C45.4314 83.8594 46.6027 83.9964 47.5023 84.2704C48.4019 84.5356 49.159 85.1323 49.7736 86.0605C50.3882 86.9798 50.6955 88.0891 50.6955 89.3886C50.6955 90.5819 50.4461 91.6073 49.9473 92.4648C49.4485 93.3222 48.776 93.941 47.9299 94.3211C47.0926 94.7012 45.8011 94.8913 44.0553 94.8913H42.4921V101.959H39.4727V83.8594ZM42.4921 86.1798V92.4913H43.9885C45.3246 92.4913 46.2464 92.2438 46.7541 91.7488C47.2707 91.2538 47.529 90.4493 47.529 89.3355C47.529 88.5046 47.3598 87.8328 47.0213 87.3201C46.6829 86.7985 46.3088 86.4803 45.8991 86.3654C45.4982 86.2416 44.8614 86.1797 43.9885 86.1797L42.4921 86.1798ZM53.0068 83.8594H57.1085C59.0948 83.8594 60.6802 84.2085 61.8648 84.9069C63.0584 85.6052 63.958 86.6439 64.5637 88.0229C65.1782 89.4018 65.4855 90.9311 65.4855 92.6106C65.4855 94.3786 65.2094 95.9565 64.6572 97.3443C64.1139 98.7233 63.2766 99.8371 62.1454 100.686C61.0231 101.534 59.4199 101.959 57.3356 101.959H53.0068V83.8594ZM56.0263 86.2593V99.5587H57.2822C59.0369 99.5587 60.3106 98.9575 61.1033 97.7553C61.896 96.5443 62.2924 94.9311 62.2924 92.9156C62.2924 88.4781 60.6223 86.2593 57.2822 86.2593H56.0263ZM68.3848 83.8594H78.512V86.2593H71.4042V91.6692H77.0958V94.0692H71.4042V101.959H68.3848V83.8594Z"
        fill="#2C2C2C"
      />
    </g>
    <defs>
      <clipPath id="clip0_8859_457329">
        <rect
          width="116.141"
          height="118.229"
          fill="white"
          transform="translate(0.71875 0.842285)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const pdfIco2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="56"
    viewBox="0 0 58 56"
    fill="none"
  >
    <path
      d="M1.44977 54.1862C-1.67923 51.0808 1.70611 46.8136 10.8935 42.2823L16.6743 39.4312L18.9264 34.5409C20.165 31.8513 22.0137 27.4627 23.0345 24.7885L24.8906 19.9263L23.6116 16.328C22.0389 11.9035 21.478 5.25469 22.4759 2.8639C23.8275 -0.374467 28.2547 -0.0422034 30.0074 3.42915C31.3762 6.14026 31.2363 11.05 29.6137 17.2419L28.2834 22.3182L29.4551 24.2923C30.0996 25.3781 31.9821 27.9562 33.6384 30.0214L36.7514 33.8644L40.6251 33.3627C52.931 31.769 57.146 34.4776 57.146 38.3612C57.146 43.2626 47.4831 43.6672 39.3688 38.0111C37.5431 36.7385 36.2893 35.4743 36.2893 35.4743C36.2893 35.4743 31.2059 36.5013 28.7031 37.1708C26.1197 37.8619 24.8308 38.2946 21.0465 39.5624C21.0465 39.5624 19.7188 41.476 18.8535 42.8672C15.6346 48.0429 11.8761 52.3322 9.19192 53.8934C6.18663 55.6413 3.03605 55.7605 1.44977 54.1862ZM6.36023 52.4452C8.11909 51.3663 11.6791 47.1877 14.1434 43.3094L15.1411 41.7391L10.5981 44.0061C3.58152 47.5073 0.371569 50.8071 2.04073 52.8031C2.9784 53.9244 4.10011 53.8314 6.36023 52.4452ZM51.937 39.7487C53.6573 38.5529 53.4076 36.143 51.4627 35.1714C49.949 34.4152 48.7291 34.2599 44.797 34.3175C42.3807 34.481 38.4953 34.9642 37.8368 35.1113C37.8368 35.1113 39.9713 36.5753 40.9189 37.1135C42.1805 37.8286 45.2461 39.156 47.4844 39.8359C49.6922 40.5066 50.9693 40.4359 51.937 39.7487ZM33.6112 32.1896C32.57 31.1034 30.7997 28.8364 29.677 27.1518C28.2088 25.2408 27.4725 23.8925 27.4725 23.8925C27.4725 23.8925 26.3994 27.3182 25.519 29.3796L22.7722 36.1177L21.9758 37.646C21.9758 37.646 26.2096 36.2683 28.3638 35.7099C30.6453 35.1185 35.2758 34.2148 35.2758 34.2148L33.6112 32.1896ZM27.7055 8.68512C27.971 6.4734 28.0837 4.26495 27.3677 3.15129C25.3817 0.996549 22.9845 2.79342 23.3906 7.91618C23.5272 9.63951 23.9586 12.5855 24.5353 14.4013L25.5839 17.7026L26.3219 15.2161C26.7278 13.8486 27.3504 10.9096 27.7055 8.68512Z"
      fill="#05735A"
    />
  </svg>
);
export const editIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.295 2.69266L15.3075 3.70516C15.9 4.29016 15.9 5.24266 15.3075 5.82766L5.385 15.7502H2.25V12.6152L10.05 4.80766L12.1725 2.69266C12.7575 2.10766 13.71 2.10766 14.295 2.69266ZM3.75 14.2502L4.8075 14.2952L12.1725 6.92266L11.115 5.86516L3.75 13.2302V14.2502Z"
      fill="#05735A"
    />
  </svg>
);
export const editIcoGray = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.56 0.589883L17.91 1.93988C18.7 2.71988 18.7 3.98988 17.91 4.76988L4.68 17.9999H0.5V13.8199L10.9 3.40988L13.73 0.589883C14.51 -0.190117 15.78 -0.190117 16.56 0.589883ZM2.5 15.9999L3.91 16.0599L13.73 6.22988L12.32 4.81988L2.5 14.6399V15.9999Z"
      fill="#49454F"
    />
  </svg>
);
export const bottomRightArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_11955_346945)">
      <path
        d="M19 15.0088L13 21.0088L11.58 19.5888L15.17 16.0088H4V4.00879H6V14.0088H15.17L11.58 10.4288L13 9.00879L19 15.0088Z"
        fill="#49454F"
      />
    </g>
    <defs>
      <clipPath id="clip0_11955_346945">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.00878906)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const goToLinkIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_11955_346954)">
      <path
        d="M19 19.0088H5V5.00879H12V3.00879H5C3.89 3.00879 3 3.90879 3 5.00879V19.0088C3 20.1088 3.89 21.0088 5 21.0088H19C20.1 21.0088 21 20.1088 21 19.0088V12.0088H19V19.0088ZM14 3.00879V5.00879H17.59L7.76 14.8388L9.17 16.2488L19 6.41879V10.0088H21V3.00879H14Z"
        fill="#49454F"
      />
    </g>
    <defs>
      <clipPath id="clip0_11955_346954">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.00878906)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const downloadIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M18.5684 15.5V18.5H6.56836V15.5H4.56836V18.5C4.56836 19.6 5.46836 20.5 6.56836 20.5H18.5684C19.6684 20.5 20.5684 19.6 20.5684 18.5V15.5H18.5684ZM17.5684 11.5L16.1584 10.09L13.5684 12.67V4.5H11.5684V12.67L8.97836 10.09L7.56836 11.5L12.5684 16.5L17.5684 11.5Z"
      fill="#05735A"
    />
  </svg>
);
export const greenShareIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_14804_278347)">
      <path
        d="M13.5 12.06C12.93 12.06 12.42 12.285 12.03 12.6375L6.6825 9.525C6.72 9.3525 6.75 9.18 6.75 9C6.75 8.82 6.72 8.6475 6.6825 8.475L11.97 5.3925C12.375 5.7675 12.9075 6 13.5 6C14.745 6 15.75 4.995 15.75 3.75C15.75 2.505 14.745 1.5 13.5 1.5C12.255 1.5 11.25 2.505 11.25 3.75C11.25 3.93 11.28 4.1025 11.3175 4.275L6.03 7.3575C5.625 6.9825 5.0925 6.75 4.5 6.75C3.255 6.75 2.25 7.755 2.25 9C2.25 10.245 3.255 11.25 4.5 11.25C5.0925 11.25 5.625 11.0175 6.03 10.6425L11.37 13.7625C11.3325 13.92 11.31 14.085 11.31 14.25C11.31 15.4575 12.2925 16.44 13.5 16.44C14.7075 16.44 15.69 15.4575 15.69 14.25C15.69 13.0425 14.7075 12.06 13.5 12.06ZM13.5 3C13.9125 3 14.25 3.3375 14.25 3.75C14.25 4.1625 13.9125 4.5 13.5 4.5C13.0875 4.5 12.75 4.1625 12.75 3.75C12.75 3.3375 13.0875 3 13.5 3ZM4.5 9.75C4.0875 9.75 3.75 9.4125 3.75 9C3.75 8.5875 4.0875 8.25 4.5 8.25C4.9125 8.25 5.25 8.5875 5.25 9C5.25 9.4125 4.9125 9.75 4.5 9.75ZM13.5 15.015C13.0875 15.015 12.75 14.6775 12.75 14.265C12.75 13.8525 13.0875 13.515 13.5 13.515C13.9125 13.515 14.25 13.8525 14.25 14.265C14.25 14.6775 13.9125 15.015 13.5 15.015Z"
        fill="#05735A"
      />
    </g>
    <defs>
      <clipPath id="clip0_14804_278347">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const whiteShareIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_15213_39251)">
      <path
        d="M13.5 12.06C12.93 12.06 12.42 12.285 12.03 12.6375L6.6825 9.525C6.72 9.3525 6.75 9.18 6.75 9C6.75 8.82 6.72 8.6475 6.6825 8.475L11.97 5.3925C12.375 5.7675 12.9075 6 13.5 6C14.745 6 15.75 4.995 15.75 3.75C15.75 2.505 14.745 1.5 13.5 1.5C12.255 1.5 11.25 2.505 11.25 3.75C11.25 3.93 11.28 4.1025 11.3175 4.275L6.03 7.3575C5.625 6.9825 5.0925 6.75 4.5 6.75C3.255 6.75 2.25 7.755 2.25 9C2.25 10.245 3.255 11.25 4.5 11.25C5.0925 11.25 5.625 11.0175 6.03 10.6425L11.37 13.7625C11.3325 13.92 11.31 14.085 11.31 14.25C11.31 15.4575 12.2925 16.44 13.5 16.44C14.7075 16.44 15.69 15.4575 15.69 14.25C15.69 13.0425 14.7075 12.06 13.5 12.06ZM13.5 3C13.9125 3 14.25 3.3375 14.25 3.75C14.25 4.1625 13.9125 4.5 13.5 4.5C13.0875 4.5 12.75 4.1625 12.75 3.75C12.75 3.3375 13.0875 3 13.5 3ZM4.5 9.75C4.0875 9.75 3.75 9.4125 3.75 9C3.75 8.5875 4.0875 8.25 4.5 8.25C4.9125 8.25 5.25 8.5875 5.25 9C5.25 9.4125 4.9125 9.75 4.5 9.75ZM13.5 15.015C13.0875 15.015 12.75 14.6775 12.75 14.265C12.75 13.8525 13.0875 13.515 13.5 13.515C13.9125 13.515 14.25 13.8525 14.25 14.265C14.25 14.6775 13.9125 15.015 13.5 15.015Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_15213_39251">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const blackWarningIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_15213_39641)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
        fill="#49454F"
      />
    </g>
    <defs>
      <clipPath id="clip0_15213_39641">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const QMarkIcoBrown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_14799_284044)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z"
        fill="#7C5705"
      />
    </g>
    <defs>
      <clipPath id="clip0_14799_284044">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const editIcoBrown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_14799_284048)">
      <path
        d="M10.545 6.765L11.235 7.455L4.44 14.25H3.75V13.56L10.545 6.765ZM13.245 2.25C13.0575 2.25 12.8625 2.325 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28C15.825 4.9875 15.825 4.515 15.5325 4.2225L13.7775 2.4675C13.6275 2.3175 13.44 2.25 13.245 2.25ZM10.545 4.6425L2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425Z"
        fill="#7C5705"
      />
    </g>
    <defs>
      <clipPath id="clip0_14799_284048">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const bDateIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_14800_55574)">
      <path
        d="M10 5C10.925 5 11.6667 4.25 11.6667 3.33333C11.6667 3.01667 11.5833 2.725 11.425 2.475L10 0L8.575 2.475C8.41667 2.725 8.33333 3.01667 8.33333 3.33333C8.33333 4.25 9.08333 5 10 5ZM15 7.5H10.8333V5.83333H9.16667V7.5H5C3.61667 7.5 2.5 8.61667 2.5 10V17.5C2.5 17.9583 2.875 18.3333 3.33333 18.3333H16.6667C17.125 18.3333 17.5 17.9583 17.5 17.5V10C17.5 8.61667 16.3833 7.5 15 7.5ZM15.8333 16.6667H4.16667V14.1667C4.91667 14.1583 5.63333 13.8583 6.16667 13.325L7.075 12.4333L7.96667 13.325C9.05833 14.4167 10.9583 14.4083 12.0417 13.325L12.9417 12.4333L13.8333 13.325C14.3667 13.8583 15.0833 14.1583 15.8333 14.1667V16.6667ZM15.8333 12.9167C15.4083 12.9083 15.0083 12.75 14.7083 12.4417L12.9333 10.6667L11.15 12.4417C10.5333 13.0583 9.45833 13.0583 8.84167 12.4417L7.06667 10.6667L5.28333 12.4417C4.99167 12.7417 4.59167 12.9083 4.16667 12.9167V10C4.16667 9.54167 4.54167 9.16667 5 9.16667H15C15.4583 9.16667 15.8333 9.54167 15.8333 10V12.9167Z"
        fill="#ACC0B8"
      />
    </g>
    <defs>
      <clipPath id="clip0_14800_55574">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const lightGreenEnvelop = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_14800_55578)">
      <path
        d="M18.3333 5.00065C18.3333 4.08398 17.5833 3.33398 16.6666 3.33398H3.33329C2.41663 3.33398 1.66663 4.08398 1.66663 5.00065V15.0007C1.66663 15.9173 2.41663 16.6673 3.33329 16.6673H16.6666C17.5833 16.6673 18.3333 15.9173 18.3333 15.0007V5.00065ZM16.6666 5.00065L9.99996 9.15898L3.33329 5.00065H16.6666ZM16.6666 15.0007H3.33329V6.66732L9.99996 10.834L16.6666 6.66732V15.0007Z"
        fill="#ACC0B8"
      />
    </g>
    <defs>
      <clipPath id="clip0_14800_55578">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const whiteQRCodeIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <g clip-path="url(#clip0_15405_40400)">
      <path
        d="M7.625 4.875V7.125H5.375V4.875H7.625ZM8.75 3.75H4.25V8.25H8.75V3.75ZM7.625 10.875V13.125H5.375V10.875H7.625ZM8.75 9.75H4.25V14.25H8.75V9.75ZM13.625 4.875V7.125H11.375V4.875H13.625ZM14.75 3.75H10.25V8.25H14.75V3.75ZM10.25 9.75H11.375V10.875H10.25V9.75ZM11.375 10.875H12.5V12H11.375V10.875ZM12.5 9.75H13.625V10.875H12.5V9.75ZM10.25 12H11.375V13.125H10.25V12ZM11.375 13.125H12.5V14.25H11.375V13.125ZM12.5 12H13.625V13.125H12.5V12ZM13.625 10.875H14.75V12H13.625V10.875ZM13.625 13.125H14.75V14.25H13.625V13.125ZM17 5.25H15.5V3H13.25V1.5H17V5.25ZM17 16.5V12.75H15.5V15H13.25V16.5H17ZM2 16.5H5.75V15H3.5V12.75H2V16.5ZM2 1.5V5.25H3.5V3H5.75V1.5H2Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_15405_40400">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export const greenQRCodeIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <g clip-path="url(#clip0_15398_25236)">
      <path
        d="M7.625 4.875V7.125H5.375V4.875H7.625ZM8.75 3.75H4.25V8.25H8.75V3.75ZM7.625 10.875V13.125H5.375V10.875H7.625ZM8.75 9.75H4.25V14.25H8.75V9.75ZM13.625 4.875V7.125H11.375V4.875H13.625ZM14.75 3.75H10.25V8.25H14.75V3.75ZM10.25 9.75H11.375V10.875H10.25V9.75ZM11.375 10.875H12.5V12H11.375V10.875ZM12.5 9.75H13.625V10.875H12.5V9.75ZM10.25 12H11.375V13.125H10.25V12ZM11.375 13.125H12.5V14.25H11.375V13.125ZM12.5 12H13.625V13.125H12.5V12ZM13.625 10.875H14.75V12H13.625V10.875ZM13.625 13.125H14.75V14.25H13.625V13.125ZM16.25 5.25C15.8375 5.25 15.5 4.9125 15.5 4.5V3H14C13.5875 3 13.25 2.6625 13.25 2.25C13.25 1.8375 13.5875 1.5 14 1.5H16.25C16.6625 1.5 17 1.8375 17 2.25V4.5C17 4.9125 16.6625 5.25 16.25 5.25ZM17 15.75V13.5C17 13.0875 16.6625 12.75 16.25 12.75C15.8375 12.75 15.5 13.0875 15.5 13.5V15H14C13.5875 15 13.25 15.3375 13.25 15.75C13.25 16.1625 13.5875 16.5 14 16.5H16.25C16.6625 16.5 17 16.1625 17 15.75ZM2.75 16.5H5C5.4125 16.5 5.75 16.1625 5.75 15.75C5.75 15.3375 5.4125 15 5 15H3.5V13.5C3.5 13.0875 3.1625 12.75 2.75 12.75C2.3375 12.75 2 13.0875 2 13.5V15.75C2 16.1625 2.3375 16.5 2.75 16.5ZM2 2.25V4.5C2 4.9125 2.3375 5.25 2.75 5.25C3.1625 5.25 3.5 4.9125 3.5 4.5V3H5C5.4125 3 5.75 2.6625 5.75 2.25C5.75 1.8375 5.4125 1.5 5 1.5H2.75C2.3375 1.5 2 1.8375 2 2.25Z"
        fill="#05735A"
      />
    </g>
    <defs>
      <clipPath id="clip0_15398_25236">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export const greenQRCodeIcoLg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_14804_278076)">
      <path
        d="M9.5 6.5V9.5H6.5V6.5H9.5ZM11 5H5V11H11V5ZM9.5 14.5V17.5H6.5V14.5H9.5ZM11 13H5V19H11V13ZM17.5 6.5V9.5H14.5V6.5H17.5ZM19 5H13V11H19V5ZM13 13H14.5V14.5H13V13ZM14.5 14.5H16V16H14.5V14.5ZM16 13H17.5V14.5H16V13ZM13 16H14.5V17.5H13V16ZM14.5 17.5H16V19H14.5V17.5ZM16 16H17.5V17.5H16V16ZM17.5 14.5H19V16H17.5V14.5ZM17.5 17.5H19V19H17.5V17.5ZM22 7H20V4H17V2H22V7ZM22 22V17H20V20H17V22H22ZM2 22H7V20H4V17H2V22ZM2 2V7H4V4H7V2H2Z"
        fill="#307111"
      />
    </g>
    <defs>
      <clipPath id="clip0_14804_278076">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const greenCheckMarkCircleIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_14804_278072)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
        fill="#307111"
      />
    </g>
    <defs>
      <clipPath id="clip0_14804_278072">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const whitePrinterIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_14804_278080)">
      <path
        d="M14.25 6H13.5V2.25H4.5V6H3.75C2.505 6 1.5 7.005 1.5 8.25V12.75H4.5V15.75H13.5V12.75H16.5V8.25C16.5 7.005 15.495 6 14.25 6ZM6 3.75H12V6H6V3.75ZM12 12.75V14.25H6V11.25H12V12.75ZM13.5 11.25V9.75H4.5V11.25H3V8.25C3 7.8375 3.3375 7.5 3.75 7.5H14.25C14.6625 7.5 15 7.8375 15 8.25V11.25H13.5Z"
        fill="white"
      />
      <path
        d="M13.5 9.375C13.9142 9.375 14.25 9.03921 14.25 8.625C14.25 8.21079 13.9142 7.875 13.5 7.875C13.0858 7.875 12.75 8.21079 12.75 8.625C12.75 9.03921 13.0858 9.375 13.5 9.375Z"
        fill="#323232"
      />
    </g>
    <defs>
      <clipPath id="clip0_14804_278080">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const phoneIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M3.45 2.16667C3.5 2.90833 3.625 3.63333 3.825 4.325L2.825 5.325C2.48333 4.325 2.26667 3.26667 2.19167 2.16667H3.45V2.16667ZM11.6667 12.1833C12.375 12.3833 13.1 12.5083 13.8333 12.5583V13.8C12.7333 13.725 11.675 13.5083 10.6667 13.175L11.6667 12.1833V12.1833ZM4.25 0.5H1.33333C0.875 0.5 0.5 0.875 0.5 1.33333C0.5 9.15833 6.84167 15.5 14.6667 15.5C15.125 15.5 15.5 15.125 15.5 14.6667V11.7583C15.5 11.3 15.125 10.925 14.6667 10.925C13.6333 10.925 12.625 10.7583 11.6917 10.45C11.6083 10.4167 11.5167 10.4083 11.4333 10.4083C11.2167 10.4083 11.0083 10.4917 10.8417 10.65L9.00833 12.4833C6.65 11.275 4.71667 9.35 3.51667 6.99167L5.35 5.15833C5.58333 4.925 5.65 4.6 5.55833 4.30833C5.25 3.375 5.08333 2.375 5.08333 1.33333C5.08333 0.875 4.70833 0.5 4.25 0.5Z"
      fill="#ACC0B8"
    />
  </svg>
);
export const mapIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_14819_586394)">
      <path
        d="M10.0003 1.66602C6.77533 1.66602 4.16699 4.27435 4.16699 7.49935C4.16699 11.8743 10.0003 18.3327 10.0003 18.3327C10.0003 18.3327 15.8337 11.8743 15.8337 7.49935C15.8337 4.27435 13.2253 1.66602 10.0003 1.66602ZM5.83366 7.49935C5.83366 5.19935 7.70033 3.33268 10.0003 3.33268C12.3003 3.33268 14.167 5.19935 14.167 7.49935C14.167 9.89935 11.767 13.491 10.0003 15.7327C8.26699 13.5077 5.83366 9.87435 5.83366 7.49935Z"
        fill="#ACC0B8"
      />
      <path
        d="M10.0003 9.58268C11.1509 9.58268 12.0837 8.64994 12.0837 7.49935C12.0837 6.34876 11.1509 5.41602 10.0003 5.41602C8.84973 5.41602 7.91699 6.34876 7.91699 7.49935C7.91699 8.64994 8.84973 9.58268 10.0003 9.58268Z"
        fill="#ACC0B8"
      />
    </g>
    <defs>
      <clipPath id="clip0_14819_586394">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const deleteIco = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 3V4H20V6H19V19C19 20.1 18.1 21 17 21H7C5.9 21 5 20.1 5 19V6H4V4H9V3H15ZM7 19H17V6H7V19ZM9 8H11V17H9V8ZM15 8H13V17H15V8Z"
      fill="#05735A"
    />
  </svg>
);
